import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import classNames from 'classnames';

import { UqAppBarV2, UqLayoutV2, UqNavbarV2 } from '@uq-components';
import UqLayoutNarrowHeaderPage from '../components/uqLayoutNarrowHeaderPage';
import SEO from '../components/seo';

import * as styles from './contactus.module.scss';

export default function UqContactUsPage() {
  return (
    <UqLayoutV2 theme="light">
      <UqAppBarV2
        className={styles.appbar}
        static
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={styles.root}>
        <div className={classNames('master-container', styles.contactusPage)}>
          <UqLayoutNarrowHeaderPage
            pageInfo={{
              pageName: 'Contact Us',
              headerTitle: 'Contact Us',
            }}
            headerRowClass="contact-us-page"
          >
            <SEO
              title="Contact Us"
              description="Need assistance? Contact the unitQ Team. We're here to help businesses excel in product quality."
            />
            <Container className="text-center content-wrapper">
              <Row>
                <Col>
                  <Row className="mt-5 ml-md-4">
                    <Col className="layout-col">
                      <div className={classNames('text-md-center text-lg-left', styles.contentHeader)}>unitQ Headquarters</div>
                    </Col>
                  </Row>
                  <Row className={classNames('mt-5 ml-0', styles.pageRow)}>
                    <Col className={classNames('layout-col justify-content-lg-start ml-md-5', styles.pageColumn)}>
                      <div className={styles.contentSection}>
                        <div className="mr-5">
                          <img alt="Contact us" src="/images/email_img_contact-us.svg" width="70px" />
                        </div>
                        <div className="text-align-left">
                          <div className="content-column-header">Email us at:</div>
                          <a href="mailto:hello@unitq.com">hello@unitq.com</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className={classNames('mt-5 ml-0', styles.pageRow)}>
                    <Col className={classNames('layout-col justify-content-lg-start ml-md-5', styles.pageColumn)}>
                      <div className={styles.contentSection}>
                        <div className="mr-5">
                          <img alt="Contact us" src="/images/snailmail_img_contact-us.svg" width="70px" />
                        </div>
                        <div className="text-align-left">
                          <div className="content-column-header">
                            Snail mail us at:
                          </div>
                          1440 Chapin Ave., Suite 340, <br />
                          Burlingame CA 94010
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row className="mt-5">
                    <Col className="layout-col">
                      <div className={classNames('content-column-header mt-3', styles.formHeader)}>
                        Contact us. We are here to help
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-5 ml-sm-0 ml-md-4">
                    <Col className={classNames('layout-col', styles.formColumn)}>
                      <iframe
                        src="/forms/contact-us-form.html"
                        width="320"
                        height="618"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        title="contact-us-form"
                      >
                        Loading…
                      </iframe>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </UqLayoutNarrowHeaderPage>
        </div>
      </div>
    </UqLayoutV2>
  );
}
